<template>
  <div>
    <div class="layout">
      <div class="banner">
        <h2 class="h2">{{keyword}}</h2>
      </div>
      <div class="list cl">
        <travels-item v-for="item of list" :datas="item" :key="item.travelsId"/>
      </div>
      <el-pagination class="pages" background
                     layout="prev, pager, next"
                     :total="1000">
      </el-pagination>
    </div>
  </div>
</template>
<script>
  import TravelsItem from './components/TravelsItem';

  export default {
    name: 'TravelsKeyword',
    data() {
      return {
        keyword: '',
        list: [
          {
            'travelsId': 'c929878588854807a9b41b071ccb739f',
            'coverUrl': 'http://welove88.oss-cn-shenzhen.aliyuncs.com/20190930/67/a97ba481b60c4b42a3d2e9b5cc62fdcb.jpg',
            'viewingCount': 0,
            'categoryId': '69f2db6c403146b6ac525a54c02e09b9',
            'isStatus': 'edit',
            'updateDate': 1569846202000,
            'languageType': 'zh-hk',
            'title': '这是是标题1',
            'keywords': '2'
          },
          {
            'travelsId': '16f23c3aba884f6aa59567840bf7c069',
            'coverUrl': 'http://welove88.oss-cn-shenzhen.aliyuncs.com/20190930/48/e3910ecfb68f4381bd4923bd7f7fd6bd.jpg',
            'viewingCount': 0,
            'categoryId': '69f2db6c403146b6ac525a54c02e09b9',
            'isStatus': 'edit',
            'updateDate': 1569843410000,
            'languageType': 'zh-hk',
            'title': '这里是标题',
            'keywords': '亲子游;'
          },
          {
            'travelsId': '2971032e69a8489f8c2a3d17fac290a6',
            'coverUrl': 'http://zentao.258luck.com/theme/default/images/main/zt-logo.png',
            'viewingCount': 0,
            'categoryId': '1',
            'isStatus': 'edit',
            'updateDate': 1569656372000,
            'languageType': 'zh-hk',
            'title': '我的达人日志',
            'keywords': '牛逼;安逸'
          }
        ]
      };
    },
    components: {TravelsItem},
    created() {
      this.keyword = this.$route.query.keyword;
    }
  };
</script>
<style scoped lang="less">
  .banner{
    position:relative;height:200px;margin-top:30px;text-align:center;background:url(../../assets/images/banner.jpg) no-repeat top center;
    &:after{position:absolute;top:0;right:0;bottom:0;left:0;background-color:rgba(255, 111, 97, 0.7);content:'';}
    &:before{display:inline-block;height:100%;vertical-align:middle;content:'';}
    .h2{display:inline-block;position:relative;z-index:1;vertical-align:middle;text-align:center;text-shadow:3px 2px 0 #ff6f61;color:#fff;font-size:48px;font-weight:bold;}
  }
  .list{
    margin-top:30px;
    /deep/ .travels-item{
      margin:0 30px 30px 0;
      &:nth-child(3n){margin-right:0;}
    }
  }
  .pages{margin-top:10px;}
</style>
